.wrapper.wrapper-full-page {
    min-height: 100vh;
    height: auto;
}

.wrapper {
    position: relative;
    top: 0;
    height: 100vh;
    overflow-x: hidden;
}

.wrapper.wrapper-full-page .full-page {
    display: -webkit-flex;
    display: flex;
}

.full-page.section-image {
    position: static;
}

.full-page {
    -webkit-align-items: center;
    align-items: center;
    min-height: 90vh;
    padding: 100px 0 120px;
}

.section-image {
    background-size: cover;
    background-position: 50%;
    position: relative;
    width: 100%;
}

.lock-page, .login-page, .register-page {
    width: 100%;
}

.full-page .full-page-background, .full-page:after {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
}

.full-page .full-page-background {
    /* background-size: cover;
    background-position: 50%; */
    background: radial-gradient(circle, #fdbf20 0%, #a07812 100%);
    z-index: -9;
}